<template>
  <div
    class="nav-item"
    :class="{
      active: active,
      'nav-item-reverse': iconOnRight,
      loading: loading,
    }"
    v-bind="!loading && $attrs"
  >
    <span v-if="!loading" :class="{ pulse: pulse }"
      ><i class="icon" :class="`${icon}`"></i
    ></span>
    <span v-if="loading" class="d-inline-flex p-1">
      <HMSpinner class="spinner" />
    </span>
    <span
      class="text ms-md-2 me-2"
      :class="{ 'text--show-on-mobile': showTextOnMobile }"
    >
      {{ text }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: null,
    },
    active: {
      type: Boolean,
      default: false,
    },
    iconOnRight: {
      type: Boolean,
      default: false,
    },
    pulse: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    showTextOnMobile: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/components-variables.scss';

.nav-item {
  color: $dark-gray;
  display: flex;
  min-height: 24px;
  align-items: center;
  padding: 0 8px;
  cursor: pointer;
  &:hover,
  &.active {
    .text {
      color: var(--brand-color, $coral);
    }
  }
  &.loading {
    cursor: default;
    .text {
      color: $dark-gray !important;
    }
  }
}

.icon {
  color: var(--brand-color, $coral);
  width: 24px;
  text-align: center;
}
.spinner {
  color: var(--brand-color, $coral);
  width: 1rem;
  height: 1rem;
}

.text {
  flex: 1;
  display: none;
  &--show-on-mobile {
    display: block;
  }
  @include media-breakpoint-up(md) {
    display: block;
  }
}
.nav-item-reverse {
  flex-direction: row-reverse;
}
</style>
